import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';
import { Control, Controller, RefCallBack } from 'react-hook-form';
import { formatDataTestId } from 'src/lib/utils';
import { twMerge } from 'src/lib/mergeTailwind';

type SimplifiedRadioInputProps = {
  name: string;
  control: Control;
  defaultValue: string | number;
  rules: {
    required: string | boolean;
    validate: (value: string | number | object) => string | boolean;
  };
  disabled: boolean;
  error: string;
  afterChange: (value: string | number) => void;
  containerClassName: string;
  optionClassName: string;
  inputClassName: string;
};

const SimplifiedRadioInput = ({
  name,
  control,
  defaultValue,
  rules,
  children,
  disabled = false,
  error,
  afterChange = () => {},
  containerClassName,
  inputClassName,
}: PropsWithChildren<SimplifiedRadioInputProps>) => {
  const options = useMemo(
    () =>
      children && React.Children.count(children) > 0
        ? React.Children.map(children, (_child) => {
            if (_child && React.isValidElement(_child)) {
              return _child.props.value;
            }
            return null;
          })?.filter((value) => value !== null && value !== undefined)
        : [],
    [children]
  );
  // radio uses 'false' as a value, use validate instead required
  if (rules?.required) {
    const customError = rules.required;
    const customValidate = rules.validate;

    rules.validate = (v) => {
      const isValidValue =
        options && options.filter((value) => value === v).length > 0;
      if (isValidValue && v !== null && v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };
    rules.required = false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <div
          className={twMerge(
            containerClassName ?? 'flex flex-col items-center justify-center'
          )}
        >
          {React.Children.map(
            children,
            (_child, i) =>
              React.isValidElement(_child) &&
              React.cloneElement(_child as React.ReactElement, {
                name: name,
                id: name + i,
                onChange: (e: string | number) => {
                  onChange(e);
                  afterChange(e);
                },
                selectedValue: value,
                error: error,
                inputRef: i === 0 && ref,
                disabled: _child.props.disabled || disabled,
                className: twMerge(_child.props.className),
                inputClassName,
              })
          )}
        </div>
      )}
    />
  );
};

type OptionType = {
  value?: string | number;
  disabled?: boolean;
  name: string;
  id?: string;
  onChange?: (event: unknown) => void;
  optionClassName?: string;
  selectedValue?: string | number;
  error?: string;
  inputRef?: RefCallBack;
  ChildElemnt?: ReactNode;
  inputClassName?: string;
};

const Option = ({
  value,
  disabled,
  name,
  id,
  onChange = () => {},
  optionClassName,
  selectedValue,
  error,
  inputRef,
  ChildElemnt,
  inputClassName,
}: OptionType) => {
  useEffect(() => {
    if (disabled && value === selectedValue) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const isChecked = value === selectedValue;

  return (
    <label
      htmlFor={id}
      className={twMerge(
        optionClassName,
        disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'
      )}
    >
      <input
        className={twMerge(
          'appearance-none h-4 w-4 min-w-1 border rounded-full',
          'focus:ring-2 ring-offset-2',
          'checked:bg-circle-violet bg-contain bg-center bg-no-repeat',
          error ? 'border-error  ring-error' : ' border-violet  ring-violet',
          inputClassName
        )}
        id={id}
        name={name}
        disabled={disabled}
        onClick={() => onChange(value)}
        type="radio"
        value={value}
        defaultChecked={isChecked}
        aria-checked={isChecked ? 'true' : 'false'}
        data-testid={formatDataTestId(`${name}.${value}`)}
        {...(inputRef && { ref: inputRef })}
      />
      {ChildElemnt}
    </label>
  );
};

SimplifiedRadioInput.Option = Option;

export default SimplifiedRadioInput;
