import { useResponsiveVariant } from 'src/lib/hooks';
import Button from '../Button';
import { twMerge } from 'src/lib/mergeTailwind';
import { IconName } from '../Icon/Icon';

const noop = () => {};

type BackProps = {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  hideLabel?: boolean;
  hideIcon?: boolean;
  label?: string;
  icon?: IconName;
  disabled?: boolean;
  rounded?: boolean;
};

const Back = ({
  className,
  onClick,
  hideLabel = false,
  hideIcon = false,
  label: propLabel,
  icon = 'chevronLeft',
  disabled = false,
  rounded = false,
}: BackProps) => {
  const defaultLabel = useResponsiveVariant({
    default: 'Back',
    xxxs: 'Go Back',
    xs: 'Go Back',
  });

  const label = propLabel ?? defaultLabel;

  return (
    <Button
      className={twMerge(
        !rounded && 'rounded-r-none w-120px xs:w-141px',
        rounded && 'w-151px',
        'justify-center px-auto',
        !hideIcon && icon && 'xs:pr-17px',
        hideLabel && 'pr-0 xs:pr-0',
        'focus:ring-offset-violet font-extrabold border-white h-51px pl-0',
        className
      )}
      fontFamily="font-heading"
      onClick={onClick ?? noop}
      disabled={disabled}
      iconLeft={!hideIcon ? icon : undefined}
      aria-label={label}
      data-testid="backButton"
    >
      {!hideLabel && label}
    </Button>
  );
};

export default Back;
