/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useSelect } from 'downshift';
import { type Control, RegisterOptions, useController } from 'react-hook-form';

import { DropdownMenu, Icon, Tooltip } from 'src/common';
import { useEffect, useMemo } from 'react';
import { twMerge } from 'src/lib/mergeTailwind';

interface IOptions {
  id: string | number;
  title: string;
  disabled?: boolean;
}

type SelectProps = {
  id?: string;
  name: string;
  label: string;
  placeholder?: string;
  options: IOptions[];
  className?: string;
  containerClassName?: string;
  error?: string;
  control: Control & { getFieldError: (name: string) => string };
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  defaultValue?: string | number | object;
  disabledWhen?: (value: string | number | object) => boolean;
  onChange?: (value: string | number | object) => void;
  afterChange?: (value: string | number | object) => void;
  helpTextTitle?: string;
  helpTextDescription?: string;
  optional?: boolean;
  textClass?: string;
  heightClass?: string;
  readOnly?: boolean;
  displayedExtraValue?: (value: string | number) => boolean;
  input?: (v: string | number | object, options: IOptions[]) => IOptions;
  output?: (v: IOptions) => string | number | IOptions;
};

const Select: React.FC<SelectProps> = ({
  id,
  name,
  label,
  placeholder,
  options: rawOptions = [],
  className,
  containerClassName,
  error: errorField,
  control,
  rules,
  defaultValue,
  disabledWhen,
  onChange = () => {},
  afterChange = () => {},
  helpTextTitle,
  helpTextDescription,
  optional = false,
  textClass = 'text-xs',
  heightClass = 'h-11',
  readOnly = false,
  displayedExtraValue = () => false,
  input = (v, options) => options.find((o) => o.id === v),
  output = (v) => v.id,
}) => {
  const options = useMemo(() => {
    if (!disabledWhen) {
      return rawOptions;
    }
    return rawOptions.map((value) => {
      value.disabled = disabledWhen(value);
      return value;
    });
  }, [rawOptions, disabledWhen]);

  const transform = useMemo(() => {
    return {
      input: input,
      output: output,
    };
  }, [input, output]);

  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const { onChange: onChangeField, value, ref } = field;

  const error =
    !errorField && control?.getFieldError && control.getFieldError(name)
      ? control.getFieldError(name)
      : errorField;

  const handleOnChange = (value: string | number | IOptions) => {
    onChange(value);
    onChangeField(value);
    afterChange(value);
  };

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    selectItem,
  } = useSelect<IOptions | null>({
    items: options,
    onSelectedItemChange: (v) => {
      if (v.selectedItem) {
        handleOnChange(transform.output(v.selectedItem));
      }
    },
    defaultSelectedItem: transform.input(value, options),
    selectedItem: transform.input(value, options),
    itemToString: (i) => (i ? i.title : ''),
    id: id ?? name,
  });

  useEffect(() => {
    if (selectedItem?.disabled) {
      handleOnChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledWhen, options, selectItem, transform, value]);

  useEffect(() => {
    if (value === '') {
      selectItem(null);
    }
    //eslint-disable-next-line
  }, [value]);

  return (
    <div
      className={twMerge(
        containerClassName ?? 'flex flex-col w-full',
        'relative'
      )}
    >
      {label && (
        <label
          htmlFor={id ?? name}
          className={twMerge(
            'bg-white px-1 absolute ml-4 h-6',

            helpTextTitle || helpTextDescription ? '-mt-3' : '-mt-3'
          )}
          {...getLabelProps()}
        >
          <div>
            <p
              className={twMerge(
                'font-bold text-xs inline leading-6',
                error
                  ? 'text-error'
                  : isOpen
                  ? 'text-violet'
                  : 'text-input-light'
              )}
            >
              {label}
            </p>
            {!!(helpTextTitle || helpTextDescription) && (
              <Tooltip
                className="inline-block"
                place="top"
                title={helpTextTitle}
                text={helpTextDescription}
                iconClassName="-top-0.5"
              ></Tooltip>
            )}
          </div>
        </label>
      )}
      <div>
        <button
          type="button"
          {...getToggleButtonProps({ ref, role: 'combobox' })}
          id={id ?? name}
          data-testid={`selectButton.${id ?? name}`}
          {...(error && { 'aria-describedby': `${id ?? name}-error` })}
          className={twMerge(
            'flex justify-between bg-white items-center w-full border rounded-lg bg-clip-border transition px-4 cursor-default ',
            textClass,
            heightClass,
            error
              ? 'text-error border-error'
              : isOpen
              ? 'text-violet border-violet'
              : 'text-input border-input-lighter',
            className
          )}
          aria-required={!optional}
          disabled={readOnly}
        >
          <span
            data-testid="description-selected-item"
            className={twMerge(
              'truncate',
              selectedItem?.id === undefined && 'text-input-light'
            )}
          >
            {!selectedItem?.title || selectedItem?.disabled
              ? placeholder
              : selectedItem?.title}
          </span>
          {displayedExtraValue && (
            <div
              data-testid={`displayValue.${id ?? name}`}
              className="absolute text-xs right-9 text-[#b4bcce]"
            >
              {displayedExtraValue(value)}
            </div>
          )}
          <Icon
            name="chevronDown"
            size="sm"
            className={twMerge('transform transition', isOpen && 'rotate-180')}
            color={!!error ? 'error' : 'violet'}
          />
        </button>
        <DropdownMenu
          id={`${id ?? name}-list`}
          isOpen={isOpen}
          error={error}
          options={options}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          getItemValue={(i: IOptions) => i.title}
          textClass={textClass}
          disabled={readOnly}
        />
      </div>
      {error && (
        <span
          id={error ? `${id ?? name}-error` : undefined}
          className="text-error text-xs mt-1 ml-4"
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
