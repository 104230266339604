import React, { ReactNode, useState } from 'react';
import Tooltip from '../Tooltip';
import { twMerge } from 'src/lib/mergeTailwind';

type FilterType = 'number' | 'decimal' | 'none';
const filterRegex: Record<FilterType, RegExp> = {
  number: /[0-9]/,
  decimal: /[0-9]|\./,
  none: /./,
};

export type InputProps = {
  name?: string;
  id?: string;
  type?: string;
  label?: ReactNode;
  error?: string | boolean;
  containerClassName?: string;
  className?: string;
  maskedFocus?: boolean;
  optional?: boolean;
  textClass?: string;
  heightClass?: string;
  afterBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  helpTextTitle?: string;
  helpTextDescription?: string;
  maxLength?: number;
  filter?: FilterType;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      id,
      type,
      label,
      error,
      containerClassName,
      className = '',
      maskedFocus = false,
      optional = false,
      textClass = 'text-xs',
      heightClass = 'h-11',
      onBlur = () => {},
      afterBlur = () => {},
      helpTextTitle,
      helpTextDescription,
      maxLength,
      filter = 'none',
      ...props
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);

    const customOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onBlur(e);
      afterBlur(e);
    };

    const onPasteHandle: React.ClipboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      if (filter === 'none') {
        const key = event.clipboardData.getData('text/plain');
        verifyNumeric(key, event);
      }
    };
    const onlyNumbersHandling: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      const key = event.key;
      if (filter !== 'none' && key.length === 1) {
        verifyNumeric(key, event);
      }
    };

    const verifyNumeric = (
      key: string,
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.ClipboardEvent<HTMLInputElement>
    ) => {
      const regex = filterRegex[filter];
      if (!regex.test(key)) {
        event.preventDefault();
      }
    };

    const inputType = type === 'dollar' ? 'number' : type;

    return (
      <div className={containerClassName}>
        <div className="flex flex-col relative">
          {label && (
            <label
              htmlFor={id ?? name}
              className={twMerge(
                'bg-white px-1 font-bold absolute left-4',
                textClass,
                helpTextTitle || helpTextDescription ? '-mt-3' : '-mt-3'
              )}
            >
              <div>
                <p
                  className={twMerge(
                    'font-bold text-xs inline leading-6',
                    error
                      ? 'text-error'
                      : focus || maskedFocus
                      ? 'text-violet'
                      : 'text-input-light'
                  )}
                >
                  {label}
                </p>
                {!!(helpTextTitle || helpTextDescription) && (
                  <Tooltip
                    className="inline-block"
                    place="top"
                    title={helpTextTitle}
                    text={helpTextDescription}
                    iconClassName="-top-0.5"
                  ></Tooltip>
                )}
              </div>
            </label>
          )}
          <div className="flex items-center">
            {type === 'dollar' && (
              <div className="text-sm absolute left-2">$</div>
            )}
            <input
              className={twMerge(
                'w-full px-4 text-input border rounded-lg',
                heightClass,
                textClass,
                error
                  ? 'border-error ring-error-light'
                  : 'border-input-lighter ring-input-light-faded focus:border-violet ring-violet-faded',
                type === 'dollar' && 'pl-5',
                className
              )}
              required={!optional}
              ref={ref}
              aria-invalid={error ? 'true' : 'false'}
              {...(error && { 'aria-describedby': `${id ?? name}-error` })}
              type={inputType ?? 'text'}
              id={id ?? name}
              name={name}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={(e) => {
                setFocus(false);
                customOnBlur(e);
              }}
              data-testid={`${id ?? name}.input`}
              onKeyDown={onlyNumbersHandling}
              onPaste={onPasteHandle}
              maxLength={maxLength}
              {...props}
            />
          </div>
          {error && (
            <span
              id={`${id ?? name}-error`}
              className="text-error text-xs mt-1 ml-4"
            >
              {error}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
