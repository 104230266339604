import { twMerge } from 'src/lib/mergeTailwind';

type DetailItemProps = {
  title: string;
  titleClassName: string;
  textClassName: string;
  fontBold: boolean;
  dataTestid: string;
};

const DetailItem = ({
  title,
  children,
  titleClassName,
  textClassName,
  fontBold = true,
  dataTestid,
}: React.PropsWithChildren<DetailItemProps>) => (
  <>
    {children !== '$0' && (
      <>
        <dt
          className={twMerge(
            'text-violet text-sm',
            fontBold ? 'font-bold' : 'font-light',
            titleClassName
          )}
          data-testid={`${dataTestid}-dt`}
        >
          {title}
        </dt>
        <dd
          className={twMerge(
            'text-input leading-4 text-sm pb-3',
            textClassName
          )}
          data-testid={`${dataTestid}-dd`}
        >
          {children}
        </dd>
      </>
    )}
  </>
);

export default DetailItem;
