import { useState } from 'react';
import { UseFormProps, useForm as useHookForm } from 'react-hook-form';
import config from 'src/config';
import { useErrorHandling } from '.';
import merge from 'lodash.merge';

const defaultHookFormOptions = {
  shouldUnregister: true,
};

type useFormProps = {
  onError?: (args: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any;
    setFormError: React.Dispatch<React.SetStateAction<string | null>>;
    defaultOnError: (e?: unknown) => Promise<void>;
  }) => Promise<void>;
} & UseFormProps;

const useForm = ({ onError, ...hookFormOptions }: useFormProps = {}) => {
  const { handleError } = useErrorHandling();
  const [formError, setFormError] = useState<string | null>(null);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const {
    handleSubmit: defaultHandleSubmit,
    formState,
    control,
    ...hookForm
  } = useHookForm({ ...defaultHookFormOptions, ...hookFormOptions });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (cb: any) =>
    defaultHandleSubmit(
      async (...args) => {
        try {
          setFormError(null);

          await cb(...args);

          setIsSubmitSuccessful(true);
        } catch (err) {
          if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
          if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(formState);
          }

          setIsSubmitSuccessful(false);

          const defaultOnError = async (e = err) => {
            const error = await handleError(e);
            setFormError(error);
          };

          if (onError) {
            await onError({
              err,
              setFormError,
              defaultOnError,
            });
          } else {
            await defaultOnError();
          }
        }
      },
      (fieldValues) => {
        if (config.debug) {
          // eslint-disable-next-line no-console
          console.log({
            info: 'Field form on error validation',
            value: fieldValues,
          });
        }
      }
    );

  const resetValueDefault = (fieldName: string) => {
    const { setValue } = hookForm;

    const defaultValue = fieldName
      .split('.')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((o: any, i: string) => o[i], control._defaultValues.current);
    setValue(fieldName, defaultValue);
  };
  const getFieldError = (fieldName: string) => {
    try {
      const error = fieldName
        .split('.')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((o: any, i: string) => o[i], formState.errors);
      return error.message;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return undefined;
    }
  };

  return {
    ...hookForm,
    control: merge(control, { getFieldError: getFieldError }),
    errors: formState.errors,
    formState,
    isSubmitSuccessful,
    formError,
    resetValueDefault,
    setFormError,
    handleSubmit,
  };
};

export default useForm;
