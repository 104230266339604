import React from 'react';
import { type Control, RegisterOptions, useController } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import Input from 'src/common/Input';
import { InputProps } from './Input/Input';

const noop = (v: string | number) => v;

export type MaskedInputProps = {
  name: string;
  control: Control;
  rules: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  mask: string;
  defaultValue?: string | number;
  transform?: {
    input: (value: string | number) => string | number;
    output: (value: string | number) => string | number;
  };
} & InputProps;

const MaskedInput = ({
  name,
  control,
  rules,
  mask,
  defaultValue,
  transform = { input: (value) => value, output: (value) => value },
  ...props
}: MaskedInputProps) => {
  const transformIn = transform?.input ?? noop;
  const transformOut = transform?.output ?? noop;
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue: transformOut(defaultValue || ''),
  });

  const [maskedFocus, setMaskedFocus] = React.useState(false);

  return (
    <ReactInputMask
      mask={mask}
      value={transformIn(field.value)}
      onChange={({ target: { value } }) => field.onChange(transformOut(value))}
      onFocus={() => setMaskedFocus(true)}
      onBlur={() => setMaskedFocus(false)}
      {...props}
    >
      <Input ref={field.ref} name={name} {...props} maskedFocus={maskedFocus} />
    </ReactInputMask>
  );
};

export default MaskedInput;
