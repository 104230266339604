import React, { PropsWithChildren, ReactNode } from 'react';

import { Icon } from 'src/common';
import ProgressBar from './ProgressBar';
import { twMerge } from 'src/lib/mergeTailwind';
import { IconColor, IconName } from '../Icon/Icon';

type TitleSizes = 'small' | 'normal';

const titleSizes = {
  small: 'text-2xl font-bold',
  normal: 'text-2xl md:text-3xl font-extrabold',
};

export type CardProps = {
  icon?: IconName;
  iconColor?: IconColor;
  title?: string;
  titleClassName?: string;
  titleSize?: TitleSizes;
  subtitle?: ReactNode;
  subTitleClassName?: string;
  className?: string;
  contentClassName?: string;
  childrenClassName?: string;
  progressBar?: { totalNotches: number; filledNotches: number };
  displayEffectiveDate?: boolean;
  containerClassName?: string;
  hiddenTop?: boolean;
};

const Card = React.forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
  (
    {
      icon,
      iconColor = 'violet',
      title,
      titleClassName,
      titleSize = 'normal',
      subtitle,
      subTitleClassName,
      children,
      className,
      contentClassName,
      childrenClassName,
      progressBar,
      containerClassName,
      hiddenTop = false,
      ...containerProps
    },
    ref
  ) => {
    titleClassName = twMerge(
      'text-violet',
      titleSizes[titleSize],
      titleClassName
    );

    return (
      <div
        className={twMerge(
          'bg-white relative rounded-t-2xl shadow-card z-20',
          containerClassName
        )}
        ref={ref}
        {...containerProps}
      >
        {progressBar && (
          <div className="block mt-6">
            <ProgressBar {...progressBar} />
          </div>
        )}
        <div
          className={twMerge(
            'flex flex-col items-center relative px-3 py-6',
            'md:px-6',
            'lg:p-6',
            className
          )}
        >
          {(title || subtitle || icon) && (
            <div
              className={twMerge(
                'flex flex-col items-center max-w-xl text-center mb-5',
                contentClassName
              )}
            >
              {icon && (
                <Icon
                  name={icon}
                  color={iconColor}
                  size="3xl"
                  className="mb-5"
                />
              )}
              <h1 className={titleClassName} data-testid="title">
                {title}
              </h1>
              {subtitle && (
                <h2
                  className={subTitleClassName ?? 'mt-2 text-center text-input'}
                  data-testid="subtitle"
                >
                  {subtitle}
                </h2>
              )}
            </div>
          )}
          <div className={childrenClassName ?? 'w-full sm:px-6'}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default Card;
