import Next from './Next';
import Back from './Back';
import Premium from './Premium';
import NeedAssistance from './NeedAssistance';
import { twMerge } from 'src/lib/mergeTailwind';

type FooterType = React.PropsWithChildren<
  {
    displayAssitance?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
>;

const Footer = ({
  children,
  className,
  displayAssitance = true,
  ...props
}: FooterType) => {
  return (
    <>
      <div
        {...props}
        className={twMerge(
          'h-24 bg-violet z-10 text-base ',
          'flex flex-row-reverse justify-center items-center',
          'space-x-reverse space-x-4',
          'xs:space-x-reverse xs:space-x-4',
          'shadow-mmd rounded-b-2xl',
          className
        )}
      >
        {children}
      </div>
      {displayAssitance && <NeedAssistance />}
    </>
  );
};

Footer.Next = Next;
Footer.Premium = Premium;
Footer.Back = Back;

export default Footer;
