import React, { PropsWithChildren } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { twMerge } from 'src/lib/mergeTailwind';

type CheckPosition = 'left' | 'rigth';
type SizeCheckBox = 'sm' | 'md' | 'lg';
type LayoutCheckBox = 'vertical' | 'inline';

type CheckboxProps = {
  name: string;
  control: Control;
  containerClassName: string;
  error: string;
  rules: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  checkPosition: CheckPosition;
  defaultValue: boolean;
  size: SizeCheckBox;
  layout: LayoutCheckBox;
};

const Checkbox = ({
  name,
  control,
  children,
  containerClassName,
  error,
  rules,
  checkPosition = 'left',
  defaultValue = false,
  size = 'lg',
  layout,
}: PropsWithChildren<CheckboxProps>) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { value, onChange, ...inputProps } }) => {
      return (
        <div className={containerClassName}>
          <label
            className={twMerge(
              'flex',
              checkPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
              layout === 'vertical' && 'space-x-4',
              layout === 'inline' && 'items-center space-x-2',
              error && 'text-error'
            )}
          >
            <div>
              <input
                className={twMerge(
                  'appearance-none cursor-pointer block min-w-1 border',
                  size === 'sm' && 'h-4 w-4',
                  size === 'md' && 'h-5 w-5',
                  size === 'lg' && 'h-6 w-6',
                  !!error && 'border-error',
                  !error && 'border-violet',
                  value
                    ? 'bg-check bg-contain bg-center bg-no-repeat'
                    : 'bg-white'
                )}
                type="checkbox"
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                value={value}
                checked={value}
                data-testid={`${name}.checkbox`}
                {...inputProps}
              />
            </div>
            <div>
              {React.Children.map(children, (child) => {
                return (
                  React.isValidElement(child) &&
                  React.cloneElement(
                    child as React.ReactElement,
                    { ...child.props, name, value, size, error },
                    child.props.children
                  )
                );
              })}
            </div>
          </label>
        </div>
      );
    }}
  />
);

type GroupProps = {
  layout: LayoutCheckBox;
  className: string;
  error?: string;
  value?: unknown;
};

const Group = ({
  layout = 'vertical',
  className,
  children,
  error,
}: React.PropsWithChildren<GroupProps>) => {
  return (
    <div
      className={twMerge(
        'flex',
        layout === 'vertical' &&
          'flex-col max-w-md space-y-4 mx-auto mt-6 mb-5',
        layout === 'inline' && 'flex-wrap items-center p-2 w-full max-w-full',
        className
      )}
    >
      {React.Children.map(children, (child) => {
        return (
          React.isValidElement(child) &&
          React.cloneElement(
            child as React.ReactElement,
            { ...child.props, layout, error },
            child.props.children
          )
        );
      })}
    </div>
  );
};
type TextTagProps = React.HTMLAttributes<HTMLParagraphElement> & {
  size: SizeCheckBox;
  error?: string;
  value?: unknown;
};

const Label = ({ className, children, ...props }: TextTagProps) => (
  <p
    className={twMerge(
      props.size === 'lg' && 'text-[13px] font-bold',
      props.size === 'sm' && 'text-xs',
      props.size === 'sm' && !!props.value && 'font-bold',
      !!props.error && 'text-error',
      className
    )}
    {...props}
  >
    {children}
  </p>
);

const SubText = ({ children, ...props }: TextTagProps) => (
  <p
    className={twMerge(
      props.size === 'sm' && 'text-xs',
      props.size === 'lg' && 'text-[13px]',
      !!props.error && 'text-error'
    )}
    {...props}
  >
    {children}
  </p>
);

Checkbox.Group = Group;
Checkbox.Label = Label;
Checkbox.SubText = SubText;

export default Checkbox;
