import Icon from 'src/common/Icon';
import Button from '../Button';
import useFile from 'src/lib/hooks/useFile';
import FormSkipButton from 'src/products/shared/Discounts/FormSkipButton';
import {
  invalidExtensionError,
  invalidFileNameLengthError,
  invalidFileSizeError,
} from './constants';
import { twMerge } from 'src/lib/mergeTailwind';
import get from 'lodash.get';
import { Control, RegisterOptions } from 'react-hook-form';
import { IconName } from '../Icon/Icon';
const _15MB = 15728640;

type FileInputProps = {
  btnText: string;
  control: Control;
  name: string;
  defaultValue: File;
  rules: RegisterOptions;
  buttonClassName: string;
  icon: IconName;
  FormType: string;
  nextPage: () => void;
  error: string;
  extensions: string[];
  limitSize: number;
  maxFileNameLength: number;
  afterRemoveSelectedFile: () => void;
};

const FileInput = ({
  btnText = 'Upload form',
  control,
  name,
  defaultValue,
  rules,
  buttonClassName,
  icon = 'arrowUp',
  FormType,
  nextPage,
  error: validationError,
  extensions,
  limitSize = _15MB,
  maxFileNameLength = 100,
  afterRemoveSelectedFile = () => {},
}: FileInputProps) => {
  const {
    error,
    file,
    setError,
    setFile,
    field,
    removeSelectedFile,
    openFileBrowser,
    validateFileExtension,
    validateFileSize,
    validateFileNameLength,
  } = useFile({
    name,
    control,
    rules,
    defaultValue,
    options: {
      extensions,
      limitSize,
      maxFileNameLength,
    },
  });
  const { onChange, ref } = field;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    if (file) {
      setError(undefined);
      setFile(file);

      if (!validateFileNameLength(file)) {
        setError(invalidFileNameLengthError());
      }

      if (!validateFileSize(file)) {
        setError(invalidFileSizeError());
      }

      if (!validateFileExtension(file)) {
        setError(invalidExtensionError());
      }

      onChange(file);
    } else {
      onChange(null);
    }
  };

  return (
    <div className="flex flex-col space-y-5 items-center">
      {!file && (
        <>
          <label htmlFor={name} className="sr-only">
            {btnText}
          </label>
          <Button
            onClick={openFileBrowser}
            color="dark"
            variant="rectangular"
            iconLeft={icon}
            type="button"
            {...(error && {
              'aria-describedby': `${name}-error`,
            })}
            className={buttonClassName}
            data-testid={`${name}`}
          >
            {btnText}
          </Button>
          {validationError && (
            <span className="text-error text-sm">Form upload is required.</span>
          )}
        </>
      )}
      {!!file && (
        <div
          className={twMerge(
            'flex items-center h-12 w-76 mt-5 px-4 bg-white border-2 rounded-full   xs:px-17px  2xl:h-14',
            !error
              ? 'border-violet text-input-light'
              : 'border-error text-error'
          )}
        >
          <div className="w-4">
            <Icon
              name={!error ? 'paperClipGray' : 'paperClipRed'}
              size="lg"
              disabled
              className={'w-4'}
            ></Icon>
          </div>
          <div className=" text-sm font-normal pl-2 ml-2 mr-auto truncate">
            {get(file, 'name')}
          </div>
          <div className="w-4">
            <Icon
              name={!error ? 'trashCan' : 'trashCanRed'}
              size="lg"
              onClick={() => {
                removeSelectedFile();
                afterRemoveSelectedFile();
              }}
              className={'w-4 cursor-pointer'}
            ></Icon>
          </div>
        </div>
      )}
      {!!error && (
        <>
          <div id={`${name}-error`} className="text-center text-error text-sm">
            {error}{' '}
            <FormSkipButton
              isFooter={false}
              FormType={FormType}
              nextPage={nextPage}
            />
            .
          </div>
          <button
            onClick={openFileBrowser}
            className="text-sm font-bold text-error underline"
          >
            Replace File
          </button>
        </>
      )}
      <input
        id={name}
        ref={ref}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept="application/*"
        data-testid="input"
      />
    </div>
  );
};

export default FileInput;
